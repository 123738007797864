import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Switch, Route, Redirect } from 'react-router-dom'
import styled from 'styled-components'

import AppHeader from '../UI/AppHeader'
import Contact from '../UI/Contact'
import Contacts from '../UI/Contacts'
import Home from '../UI/Home'
import Invitation from '../UI/Invitation'
import Invitations from '../UI/Invitations'
import Presentation from '../UI/Presentation'
import Presentations from '../UI/Presentations'
import User from '../UI/User'
import Users from '../UI/Users'
import Settings from '../UI/Settings'

import { check } from '../UI/CanUser'

const Frame = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`
const Main = styled.main`
  flex: 9;
  padding: 30px;
`

function AdminRoutes(props) {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route exact path={`${path}/forgot-password`}>
          <Redirect to="/admin" />
        </Route>
        <Route exact path={`${path}/password-reset`}>
          <Redirect to="/admin" />
        </Route>
        <Route exact path={`${path}/account-setup`}>
          <Redirect to="/admin" />
        </Route>
        <Route exact path={`${path}/login`}>
          <Redirect to="/admin" />
        </Route>
        <Route
          path="/admin"
          exact
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  <Home sendRequest={props.sendMessage} />
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          exact
          path={`${path}/invitations/`}
          render={({ match, history }) => {
            if (check('invitations:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Invitations
                      history={history}
                      sendRequest={props.sendMessage}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/invitations/:invitationId`}
          render={({ match, history }) => {
            if (check('invitations:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Invitation
                      history={history}
                      sendRequest={props.sendMessage}
                      invitationId={match.params.invitationId}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/contacts`}
          exact
          render={({ match, history }) => {
            if (check('contacts:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Contacts
                      history={history}
                      sendRequest={props.sendMessage}
                      setWaitingForContacts={props.setWaitingForContacts}
                      setWaitingForPendingConnections={
                        props.setWaitingForPendingConnections
                      }
                      waitingForContacts={props.waitingForContacts}
                      waitingForPendingConnections={
                        props.waitingForPendingConnections
                      }
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/contacts/:contactId`}
          render={({ match, history }) => {
            if (check('contacts:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Contact
                      history={history}
                      sendRequest={props.sendMessage}
                      contactId={match.params.contactId}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/presentations`}
          exact
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  <Presentations
                    history={history}
                    presentationReports={props.presentationReports}
                  />
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/presentations/:presentationId`}
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  <Presentation
                    history={history}
                    presentation={match.params.presentationId}
                  />
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/verification`}
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  <p>Verification</p>
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/messages`}
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  <p>Messages</p>
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/users`}
          render={({ match, history }) => {
            if (check('users:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Users sendRequest={props.sendMessage} />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/users/:userId`}
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader match={match} />
                <Main>
                  <User history={history} />
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/settings`}
          render={({ match, history }) => {
            if (check('settings:update')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Settings
                      updateTheme={props.updateTheme}
                      saveTheme={props.saveTheme}
                      undoStyle={props.undoStyle}
                      stylesArray={props.stylesArray}
                      addStylesToArray={props.addStylesToArray}
                      removeStylesFromArray={props.removeStylesFromArray}
                      sendRequest={props.sendMessage}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        {/* Redirect to root if no route match is found */}
        <Route render={() => <Redirect to="/admin" />} />
      </Switch>
    </>
  )
}

export default AdminRoutes
