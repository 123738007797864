import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './Root.css'
import avatar from '../assets/logo.png'
import logo from '../assets/logo.png'
import QRCode from 'qrcode.react'
import styled from 'styled-components'

export const VerifierButton = styled.button`
  margin: auto;
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1.3em;
  text-transform: uppercase;
  color: ${(props) => props.theme.text_light};
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: #407bd4;
  :hover {
    cursor: pointer;
  }
`
const ButtonWrapper = styled.span`
  display: flex;
  width: 100%;
  justify-content: space-around;
`
const Attribute = styled.p`
  margin-bottom: 0;
`
const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  background: #fff;
`

function Root(props) {
  const contactsState = useSelector((state) => state.contacts)
  const invitationURL = useSelector((state) => state.invitations.invitationURL)
  const settingsState = useSelector((state) => state.settings)

  const [waitingForInvitation, setWaitingForInvitation] = useState(false)
  const [waitingForConnection, setWaitingForConnection] = useState(false)
  const [waitingForVerification, setWaitingForVerification] = useState(false)
  const [connected, setConnected] = useState(false)
  const [chosenCred, setChosenCred] = useState('')

  useEffect(() => {
    if (
      !waitingForInvitation &&
      props.anonWebsocket &&
      props.readyForAnonMessages
    ) {
      props.sendRequest('INVITATIONS', 'CREATE', {
        alias: 'Invitation',
        invitationMode: 'once',
        accept: 'auto',
        public: false,
        invitationStatus: 'active',
        invitationDescription: 'Invited from Admin Verifier Home Page',
      })
      setWaitingForInvitation(true)
    }
  }, [props.anonWebsocket, props.readyForAnonMessages])

  useEffect(() => {
    if (invitationURL !== '') {
      setWaitingForConnection(true)
    }
    if (contactsState.connection.state === 'active' && waitingForConnection) {
      setConnected(true)
    }
  }, [props.QRCodeURL, contactsState.connection, waitingForConnection])

  const requestPresentations = (cred) => {
    setChosenCred(cred)
    switch (cred) {
      case 'TRUSTED_TRAVELER':
        props.sendRequest('PRESENTATIONS', 'REQUEST', {
          connection_id: contactsState.connection.connection_id,
          schema_id: settingsState.schemas.SCHEMA_TRUSTED_TRAVELER,
          attributes: ['trusted_traveler_id'],
        })
        setWaitingForVerification(true)

        break
      case 'BOARDING_PASS':
        props.sendRequest('PRESENTATIONS', 'REQUEST', {
          connection_id: contactsState.connection.connection_id,
          schema_id: settingsState.schemas.SCHEMA_BOARDING_PASS,
          attributes: [
            'passenger_given_names',
            'passenger_family_names',
            'pnr',
            'sequence_number',
            'ticket_eticket_number',
            'ticket_flight_number',
            'ticket_class',
            'ticket_seat_number',
          ],
        })
        setWaitingForVerification(true)

        break
      default:
        return
    }
  }

  const verifiedDisplay = () => {
    switch (chosenCred) {
      case 'TRUSTED_TRAVELER':
        return (
          <div
            className="right-fold landing-col-6"
            style={{ backgroundColor: '#26A908' }}
          >
            <h1 className="header">Credentials Verified!</h1>
            <p className="para">
              Trusted Traveler ID:{' '}
              {props.verifiedCredential.trusted_traveler_id.raw}
            </p>
          </div>
        )
      case 'BOARDING_PASS':
        return (
          <div
            className="right-fold landing-col-6"
            style={{ backgroundColor: '#26A908' }}
          >
            <h1 className="header">Credentials Verified!</h1>
            <Attribute className="para">
              Passenger Given Names:{' '}
              {props.verifiedCredential.passenger_given_names.raw}
            </Attribute>
            <Attribute className="para">
              Passenger Family Names:{' '}
              {props.verifiedCredential.passenger_family_names.raw}
            </Attribute>
            <Attribute className="para">
              PNR: {props.verifiedCredential.pnr.raw}
            </Attribute>
            <Attribute className="para">
              Sequence Number: {props.verifiedCredential.sequence_number.raw}
            </Attribute>
            <Attribute className="para">
              Ticket E-Ticket Number:{' '}
              {props.verifiedCredential.ticket_eticket_number.raw}
            </Attribute>
            <Attribute className="para">
              Ticket Flight Number:{' '}
              {props.verifiedCredential.ticket_flight_number.raw}
            </Attribute>
            <Attribute className="para">
              Ticket Class: {props.verifiedCredential.ticket_class.raw}
            </Attribute>
            <Attribute className="para">
              Ticket Seat Number:{' '}
              {props.verifiedCredential.ticket_seat_number.raw}
            </Attribute>
          </div>
        )

      default:
        return (
          <div className="right-fold landing-col-6">
            <h1 className="header">Credentials Verified!</h1>
            <p>Credential Data could not be displayed</p>
          </div>
        )
    }
  }

  return (
    <>
      <div className="landing-container-fluid">
        <div className="landing-row">
          <div className="home landing-col s12">
            <div className="landing-col upper-fold">
              <img src={logo} className="img-fluid" alt="" />
              <div className="landing-container">
                <div className="landing-row">
                  <div className="avatar-container left-fold landing-col-6">
                    <img src={avatar} className="avatar" alt="" />
                  </div>
                  {connected ? (
                    waitingForVerification ? (
                      props.verificationStatus !== undefined ? (
                        props.verificationStatus ? (
                          props.verifiedCredential ? (
                            verifiedDisplay()
                          ) : (
                            <div className="right-fold landing-col-6">
                              <h1 className="header">Credentials Verified!</h1>
                              <p className="para">
                                No Credential Data Was Passed
                              </p>
                            </div>
                          )
                        ) : (
                          <div className="right-fold landing-col-6">
                            <h1 className="header">Verification Failed</h1>
                            <p className="para">
                              There was a problem verifying your credential.
                              Please try again or contact support.
                            </p>
                          </div>
                        )
                      ) : (
                        <div className="right-fold landing-col-6">
                          <h1 className="header">Verify your credentials</h1>
                          <p className="para">
                            You will now receive a request on your mobile app to
                            send your credential to us for verification
                          </p>
                        </div>
                      )
                    ) : (
                      <div className="right-fold landing-col-6">
                        <h1 className="header">
                          Choose a Credential to Verify
                        </h1>
                        <ButtonWrapper>
                          <VerifierButton
                            onClick={() =>
                              requestPresentations('TRUSTED_TRAVELER')
                            }
                          >
                            Trusted Traveler
                          </VerifierButton>
                          <VerifierButton
                            onClick={() =>
                              requestPresentations('BOARDING_PASS')
                            }
                          >
                            Boarding Pass
                          </VerifierButton>
                        </ButtonWrapper>
                      </div>
                    )
                  ) : (
                    <div className="right-fold landing-col-6">
                      <h1 className="header">Verify your credentials</h1>
                      <p className="para">
                        Simply scan the following QR code to begin the
                        verification process:
                      </p>
                      {invitationURL ? (
                        <div className="qr">
                          <p>
                            <QR
                              value={invitationURL}
                              size={256}
                              renderAs="svg"
                            />
                          </p>
                        </div>
                      ) : (
                        <p>
                          <span>Loading...</span>
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Root
