import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { CanUser } from './CanUser'
import FormContacts from './FormContacts'
import { useNotification } from './NotificationProvider'
import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'

import { clearNotificationsState } from '../redux/notificationsReducer'
import { setContactSelected } from '../redux/contactsReducer'

import {
  DataTable,
  DataRow,
  DataHeader,
  DataCell,
  AttributeTable,
  AttributeRow,
} from './CommonStylesTables'

const EditContact = styled.button`
  float: right;
  padding: 10px;
  color: ${(props) => props.theme.text_light};
  border: none;
  box-shadow: ${(props) => props.theme.drop_shadow};
  background: ${(props) => props.theme.primary_color};
`
const Spinner = styled.div`
  width: 60px;
  height: 60px;
  margin: 0;
  background: transparent;
  border-top: 3px solid
    ${(props) => (props ? props.theme.primary_color : 'green')};
  border-right: 3px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
const LoadingHolder = styled.div`
  font-size: 1.5em;
  color: ${(props) => props.theme.text_color};
  height: 200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

function Contact(props) {
  const dispatch = useDispatch()
  const contactsState = useSelector((state) => state.contacts)
  const notificationsState = useSelector((state) => state.notifications)
  const presentationsState = useSelector((state) => state.presentations)

  const contactSelected = contactsState.contactSelected
  const error = notificationsState.errorMessage
  const success = notificationsState.successMessage
  const warning = notificationsState.warningMessage
  const presentations = presentationsState.presentationReports

  // Accessing notification context
  const setNotification = useNotification()

  const [connections, setConnections] = useState([])
  const [waitingForContact, setWaitingForContact] = useState(false)

  const contactId = props.contactId

  useEffect(() => {
    //(AmmonBurgi) Stop waiting for contactSelected if the contact_id matches the target ID. If no match is found, fetch the needed contact.
    if (contactSelected && contactSelected.contact_id === contactId) {
      setWaitingForContact(false)
      setConnections(contactSelected.Connections)
    } else {
      setWaitingForContact(true)
      props.sendRequest('CONTACTS', 'GET', {
        contact_id: contactId,
        additional_tables: ['Demographics', 'Passports'],
      })
    }
  }, [contactSelected, contactId])

  useEffect(() => {
    //(AmmonBurgi) Update the selectedContact if the most recent presentation record belongs to this contact.
    if (
      presentations.length !== 0 &&
      presentations[0].state === 'verified' &&
      presentations[0].contact_id === contactId
    ) {
      setWaitingForContact(true)
      props.sendRequest('CONTACTS', 'GET', {
        contact_id: contactId,
        additional_tables: ['Demographics', 'Passports'],
      })
    }
  }, [presentations])

  useEffect(() => {
    if (success) {
      setNotification(success, 'notice')
      dispatch(clearNotificationsState())
    } else if (error) {
      setNotification(error, 'error')
      dispatch(clearNotificationsState())
    } else if (warning) {
      setNotification(warning, 'warning')
      dispatch(clearNotificationsState())
    } else return
  }, [error, success, warning, setNotification, dispatch])

  // Modal state
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false)

  const closeContactModal = () => setContactModalIsOpen(false)

  // let demographicData = ''
  let passportData = ''

  if (
    contactSelected.Passport !== null &&
    contactSelected.Passport !== undefined
  ) {
    let rawImage = contactSelected.Passport.photo

    const handleImageSrc = (rawImage) => {
      let bytes = new Uint8Array(rawImage)
      bytes = Buffer.from(rawImage).toString('base64')
      // Check the MIME type
      // let result = null
      // if (atob(bytes).charAt(0) === 'i')
      // result = `data:image/png;base64,${atob(bytes)}`
      // else if (atob(bytes).charAt(0) === '/')
      // result = `data:image/jpeg;base64,${atob(bytes)}`
      // else if (atob(bytes).charAt(0) === 'R')
      // result = `data:image/gif;base64,${atob(bytes)}`
      // else if (atob(bytes).charAt(0) === 'U')
      // result = `data:image/webp;base64,${atob(bytes)}`
      // else
      let result = atob(bytes)
      return result
    }

    let test = handleImageSrc(rawImage)
    passportData = (
      <div>
        <h2>Passport Information</h2>
        <AttributeTable>
          <tbody>
            <AttributeRow>
              <th>Passport Number:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.passport_number || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Surname:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.surname || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Given Name(s):</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.given_names || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Sex:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.sex || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Date of Birth:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.date_of_birth || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Place of Birth:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.place_of_birth || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Nationality:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.nationality || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Date of Issue:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.date_of_issue || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Date of Expiration:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.date_of_expiration || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Type:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.type || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Code:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.code || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Authority:</th>
              <td>
                {contactSelected.Passport !== null &&
                contactSelected.Passport !== undefined
                  ? contactSelected.Passport.authority || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Photo:</th>
              <td></td>
            </AttributeRow>
          </tbody>
        </AttributeTable>
        <img src={test} alt="Error" />
      </div>
    )
  }

  function updateContact(updatedDemographic, e) {
    e.preventDefault()
    const Demographic = {
      Demographic: { ...updatedDemographic },
    }

    props.sendRequest('DEMOGRAPHICS', 'UPDATE_OR_CREATE', updatedDemographic)

    setNotification('Contact was updated!', 'notice')

    setContactSelected({ ...contactSelected, ...Demographic })
  }

  const connectionRows = connections.map((connection) => {
    return (
      <DataRow key={connection.connection_id}>
        <DataCell>{connection.connection_id}</DataCell>
        <DataCell className="title-case">{connection.state}</DataCell>
        <DataCell>{new Date(connection.created_at).toLocaleString()}</DataCell>
      </DataRow>
    )
  })

  return (
    <>
      {!waitingForContact ? (
        <div id="contact">
          <PageHeader
            title={'Contact Details: ' + (contactSelected.label || '')}
          />
          <PageSection>
            <CanUser
              perform="contacts:update"
              yes={() => (
                <EditContact onClick={() => setContactModalIsOpen((o) => !o)}>
                  Edit
                </EditContact>
              )}
            />
            <h2>General Information</h2>
            <AttributeTable>
              <tbody>
                <AttributeRow>
                  <th>Contact ID:</th>
                  <td>{contactSelected.contact_id || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Connection Status:</th>
                  <td>
                    {contactSelected.Connections !== undefined
                      ? contactSelected.Connections[0].state || ''
                      : ''}
                  </td>
                </AttributeRow>
              </tbody>
            </AttributeTable>

            <h2>Demographic Information</h2>
            <AttributeTable>
              <tbody>
                <AttributeRow>
                  <th>Name:</th>
                  <td>{contactSelected.label || ''}</td>
                </AttributeRow>
                <AttributeRow>
                  <th>Email:</th>
                  <td>
                    {contactSelected.Demographic !== null &&
                    contactSelected.Demographic !== undefined
                      ? contactSelected.Demographic.email || ''
                      : ''}
                  </td>
                </AttributeRow>
                <AttributeRow>
                  <th>Phone:</th>
                  <td>
                    {contactSelected.Demographic !== null &&
                    contactSelected.Demographic !== undefined
                      ? contactSelected.Demographic.phone || ''
                      : ''}
                  </td>
                </AttributeRow>
                <AttributeRow>
                  <th>Address 1:</th>
                  <td>
                    {contactSelected.Demographic !== null &&
                    contactSelected.Demographic !== undefined &&
                    contactSelected.Demographic.address
                      ? contactSelected.Demographic.address.address_1 || ''
                      : ''}
                  </td>
                </AttributeRow>
                <AttributeRow>
                  <th>Address 2:</th>
                  <td>
                    {contactSelected.Demographic !== null &&
                    contactSelected.Demographic !== undefined &&
                    contactSelected.Demographic.address
                      ? contactSelected.Demographic.address.address_2 || ''
                      : ''}
                  </td>
                </AttributeRow>
                <AttributeRow>
                  <th>City:</th>
                  <td>
                    {contactSelected.Demographic !== null &&
                    contactSelected.Demographic !== undefined &&
                    contactSelected.Demographic.address
                      ? contactSelected.Demographic.address.city || ''
                      : ''}
                  </td>
                </AttributeRow>
                <AttributeRow>
                  <th>State:</th>
                  <td>
                    {contactSelected.Demographic !== null &&
                    contactSelected.Demographic !== undefined &&
                    contactSelected.Demographic.address
                      ? contactSelected.Demographic.address.state || ''
                      : ''}
                  </td>
                </AttributeRow>
                <AttributeRow>
                  <th>Zip Code:</th>
                  <td>
                    {contactSelected.Demographic !== null &&
                    contactSelected.Demographic !== undefined &&
                    contactSelected.Demographic.address
                      ? contactSelected.Demographic.address.zip_code || ''
                      : ''}
                  </td>
                </AttributeRow>
                <AttributeRow>
                  <th>Country:</th>
                  <td>
                    {contactSelected.Demographic !== null &&
                    contactSelected.Demographic !== undefined &&
                    contactSelected.Demographic.address
                      ? contactSelected.Demographic.address.country || ''
                      : ''}
                  </td>
                </AttributeRow>
              </tbody>
            </AttributeTable>
            {passportData}
          </PageSection>
          <PageSection>
            <DataTable>
              <thead>
                <DataRow>
                  <DataHeader>Connection</DataHeader>
                  <DataHeader>Status</DataHeader>
                  <DataHeader>Created At</DataHeader>
                </DataRow>
              </thead>
              <tbody>{connectionRows}</tbody>
            </DataTable>
          </PageSection>

          <FormContacts
            contactModalIsOpen={contactModalIsOpen}
            closeContactModal={closeContactModal}
            submitContact={updateContact}
          />
        </div>
      ) : (
        <LoadingHolder>
          <p>Fetching contacts, please wait...</p>
          <Spinner />
        </LoadingHolder>
      )}
    </>
  )
}

export default Contact
