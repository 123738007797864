import Axios from 'axios'
import Cookies from 'universal-cookie'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import styled, { ThemeProvider } from 'styled-components'

// Redux reducers imports
import { useSelector, useDispatch } from 'react-redux'
import {
  setLoggedIn,
  setLoggedInUserId,
  setLoggedInUsername,
  setLoggedInRoles,
  setLoggedInUserState,
  logoutUser,
} from './redux/loginReducer'
import {
  setContacts,
  setContactSelected,
  setPendingConnections,
  clearContactsState,
  setConnection,
} from './redux/contactsReducer'
import {
  setSchemas,
  setLogo,
  // getLogo,
  setOrganizationName,
  setSiteTitle,
  setSmtp,
  setTheme,
  clearSettingsState,
} from './redux/settingsReducer'
import {
  setPresentationReports,
  clearPresentationsState,
} from './redux/presentationsReducer'
import {
  setErrorMessage,
  setSuccessMessage,
  // setWarningMessage,
} from './redux/notificationsReducer'
import {
  setUsers,
  setUser,
  setRoles,
  clearUsersState,
} from './redux/usersReducer'
import {
  clearInvitationsState,
  setInvitations,
  setInvitationURL,
} from './redux/invitationsReducer'
import store from './store'

import AccountSetup from './UI/AccountSetup'
import AdminRoute from './routes/AdminRoute'
import { check } from './UI/CanUser'
import ForgotPassword from './UI/ForgotPassword'
import FullPageSpinner from './UI/FullPageSpinner'
import Login from './UI/Login'
import {
  useNotification,
  NotificationProvider,
} from './UI/NotificationProvider'
import PasswordReset from './UI/PasswordReset'
import SessionProvider from './UI/SessionProvider'
import Root from './UI/Root'
import { handleImageSrc } from '../src/UI/util'

import './App.css'

const Frame = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`
const Main = styled.main`
  flex: 9;
  padding: 30px;
`

function App() {
  let currentState
  const loginState = useSelector((state) => state.login)
  const settingsState = useSelector((state) => state.settings)
  const theme = settingsState.theme

  const dispatch = useDispatch()

  const updateState = () => {
    currentState = store.getState()
  }

  const cookies = new Cookies()

  // (AmmonBurgi) Keeps track of loading processes. The useMemo is necessary to preserve list across re-renders.
  const adminLoadingList = useMemo(() => [], [])
  const anonLoadingList = useMemo(() => [], [])

  const setNotification = useNotification()

  // Websocket reference hook
  const controllerAdminSocket = useRef()
  const controllerAnonSocket = useRef()

  // Used for websocket auto reconnect
  const [adminWebsocket, setAdminWebsocket] = useState(false)
  const [anonWebsocket, setAnonWebsocket] = useState(false)
  const [readyForAdminMessages, setReadyForAdminMessages] = useState(false)
  const [readyForAnonMessages, setReadyForAnonMessages] = useState(false)

  // State governs whether the app should be loaded. Depends on the loadingArray
  const [appIsLoaded, setAppIsLoaded] = useState(false)

  // Styles to change array
  const [stylesArray, setStylesArray] = useState([])

  // session states
  const [session, setSession] = useState('')
  const [sessionTimer] = useState(60)

  const [verificationStatus, setVerificationStatus] = useState()
  const [verifiedCredential, setVerifiedCredential] = useState('')
  const [pendingConnectionID, setPendingConnectionID] = useState('')

  const [waitingForContacts, setWaitingForContacts] = useState(false)
  const [
    waitingForPendingConnections,
    setWaitingForPendingConnections,
  ] = useState(false)

  // (JamesKEbert) Note: We may want to abstract the websockets out into a high-order component for better abstraction, especially potentially with authentication/authorization

  // Perform First Time Setup. Connect to Controller Server via Websockets

  // always configure the anon websocket for Verification
  useEffect(() => {
    if (!anonWebsocket) {
      let url = new URL('/api/anon/ws', window.location.href)
      url.protocol = url.protocol.replace('http', 'ws')
      controllerAnonSocket.current = new WebSocket(url.href)

      controllerAnonSocket.current.onopen = (event) => {
        setAnonWebsocket(true)
      }

      controllerAnonSocket.current.onclose = (event) => {
        // Auto Reopen websocket connection
        // (JamesKEbert) TODO: Converse on sessions, session timeout and associated UI

        setReadyForAnonMessages(false)
        setAnonWebsocket(false)
      }

      // Error Handler
      controllerAnonSocket.current.onerror = (event) => {
        setNotification('Client Error - Websockets', 'error')
      }

      // Receive new message from Controller Server
      controllerAnonSocket.current.onmessage = (message) => {
        const parsedMessage = JSON.parse(message.data)

        messageHandler(
          parsedMessage.context,
          parsedMessage.type,
          parsedMessage.data
        )
      }
    }
  }, [])

  // TODO: Setting logged-in user and session states on app mount
  useEffect(() => {
    Axios({
      method: 'GET',
      url: '/api/renew-session',
    })
      .then((res) => {
        if (cookies.get('sessionId')) {
          // Update session expiration date
          setSession(cookies.get('sessionId'))
          dispatch(setLoggedIn(true))

          dispatch(setLoggedInUserState(res.data))
          dispatch(setLoggedInUserId(res.data.id))
          dispatch(setLoggedInUsername(res.data.username))
          dispatch(setLoggedInRoles(res.data.roles))
        } else setAppIsLoaded(true)
      })
      .catch((error) => {
        // Unauthorized
        setAppIsLoaded(true)
      })
  }, [loginState.loggedIn, dispatch])

  // Setting up websocket and controllerSocket
  useEffect(() => {
    if (session && loginState.loggedIn) {
      let url = new URL('/api/admin/ws', window.location.href)
      url.protocol = url.protocol.replace('http', 'ws')
      controllerAdminSocket.current = new WebSocket(url.href)

      controllerAdminSocket.current.onopen = () => {
        setAdminWebsocket(true)
      }

      controllerAdminSocket.current.onclose = (event) => {
        // Auto Reopen websocket connection
        // (JamesKEbert) TODO: Converse on sessions, session timeout and associated UI

        setReadyForAdminMessages(false)
        dispatch(setLoggedIn(false))
        setAdminWebsocket(false)
      }

      // Error Handler
      controllerAdminSocket.current.onerror = (event) => {
        setNotification('Client Error - Websockets', 'error')
      }

      // Receive new message from Controller Server
      controllerAdminSocket.current.onmessage = (message) => {
        const parsedMessage = JSON.parse(message.data)

        messageHandler(
          parsedMessage.context,
          parsedMessage.type,
          parsedMessage.data
        )
      }
    }
  }, [session, loginState.loggedIn])

  // (eldersonar) Set-up site title. What about SEO? Will robots be able to read it?
  useEffect(() => {
    document.title = settingsState.siteTitle
  }, [settingsState.siteTitle])

  useEffect(() => {
    // Perform operation on websocket open
    // Run web sockets only if authenticated
    if (
      session &&
      loginState.loggedIn &&
      adminWebsocket &&
      readyForAdminMessages &&
      loginState.loggedInUserState &&
      adminLoadingList.length === 0
    ) {
      sendAdminMessage('SETTINGS', 'GET_THEME', {})
      addLoadingProcess('THEME')
      sendAdminMessage('SETTINGS', 'GET_SCHEMAS', {})
      addLoadingProcess('SCHEMAS')

      if (check('invitations:read')) {
        sendAdminMessage('INVITATIONS', 'GET_ALL', {
          params: {
            sort: [['updated_at', 'DESC']],
            pageSize: '10',
          },
        })
        addLoadingProcess('INVITATIONS')
      }

      if (check('presentations:read')) {
        sendAdminMessage('PRESENTATIONS', 'GET_ALL', {})
        addLoadingProcess('PRESENTATIONS')
      }

      if (check('roles:read')) {
        sendAdminMessage('ROLES', 'GET_ALL', {})
        addLoadingProcess('ROLES')
      }

      sendAdminMessage('SETTINGS', 'GET_ORGANIZATION', {})
      addLoadingProcess('ORGANIZATION')

      if (check('settings:update')) {
        sendAdminMessage('SETTINGS', 'GET_SMTP', {})
        addLoadingProcess('SMTP')
      }

      sendAdminMessage('IMAGES', 'GET_ALL', {})
      addLoadingProcess('LOGO')

      if (check('users:read')) {
        sendAdminMessage('USERS', 'GET_ALL', {})
        addLoadingProcess('USERS')
      }
    } else if (
      !session &&
      !loginState.loggedIn &&
      anonWebsocket &&
      readyForAnonMessages &&
      anonLoadingList.length === 0
    ) {
      sendAnonMessage('SETTINGS', 'GET_THEME', {})
      addLoadingProcess('THEME')
      sendAnonMessage('SETTINGS', 'GET_SCHEMAS', {})
      addLoadingProcess('SCHEMAS')
      sendAnonMessage('SETTINGS', 'GET_ORGANIZATION', {})
      addLoadingProcess('ORGANIZATION')
      sendAnonMessage('IMAGES', 'GET_ALL', {})
      addLoadingProcess('LOGO')
    }
  }, [
    session,
    loginState.loggedIn,
    adminWebsocket,
    readyForAdminMessages,
    loginState.loggedInUserState,
    anonWebsocket,
    readyForAnonMessages,
  ])

  // (eldersonar) Shut down the websocket
  function closeWSConnection(code, reason) {
    controllerAdminSocket.current.close(code, reason)
  }

  // Send a message to the Controller server
  function sendAnonMessage(context, type, data = {}) {
    if (anonWebsocket) {
      controllerAnonSocket.current.send(JSON.stringify({ context, type, data }))
    }
  }

  // Send a message to the Controller server
  function sendAdminMessage(context, type, data = {}) {
    if (adminWebsocket) {
      controllerAdminSocket.current.send(
        JSON.stringify({ context, type, data })
      )
    }
  }

  // Handle inbound messages
  const messageHandler = async (context, type, data = {}) => {
    //Update to current state
    updateState()

    try {
      console.log(
        `New Message with context: '${context}' and type: '${type}' with data:`,
        data
      )
      switch (context) {
        case 'ERROR':
          switch (type) {
            case 'SERVER_ERROR':
              setNotification(
                `Server Error - ${data.errorCode} \n Reason: '${data.errorReason}'`,
                'error'
              )
              break

            case 'WEBSOCKET_ERROR':
              clearLoadingProcess()
              dispatch(setErrorMessage(data.error))
              break

            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'INVITATIONS':
          switch (type) {
            case 'INVITATION':
              dispatch(setInvitationURL(data.invitation_url))
              break

            case 'INVITATIONS':
              dispatch(setInvitations(data))

              removeLoadingProcess('INVITATIONS')
              break

            case 'INVITATION_DELETED':
              const index = currentState.invitations.findIndex(
                (v) => v.invitation_id === data
              )
              let alteredInvitations = [...currentState.invitations]
              alteredInvitations.splice(index, 1)
              dispatch(setInvitations(alteredInvitations))

              break

            case 'INVITATIONS_SUCCESS':
              console.log('Invitation Success')
              dispatch(setSuccessMessage(data))
              break

            case 'INVITATIONS_ERROR':
              dispatch(setErrorMessage(data.error))
              break
            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'CONTACTS':
          switch (type) {
            case 'CONTACTS':
              dispatch(setContacts(data.contacts))
              setWaitingForContacts(false)

              break

            case 'CONTACT':
              dispatch(setContactSelected(data.contact))

              break

            case 'CONNECTION':
              dispatch(setConnection(data.connection))

              break

            case 'CONTACTS_ERROR':
              dispatch(setErrorMessage(data.error))
              break

            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'CONNECTIONS':
          switch (type) {
            case 'PENDING_CONNECTIONS':
              dispatch(setPendingConnections(data.pendingConnections))
              setWaitingForPendingConnections(false)

              break

            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'DEMOGRAPHICS':
          switch (type) {
            case 'DEMOGRAPHICS_ERROR':
              dispatch(setErrorMessage(data.error))
              break

            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'OUT_OF_BAND':
          switch (type) {
            case 'INVITATION':
              dispatch(setInvitationURL(data.invitation_url))

              break

            case 'CONNECTION_REUSE':
              console.log(data.comment)
              const message = `Connection reused for ${data.connection_id}`
              dispatch(setSuccessMessage(message))

              break

            case 'INVITATIONS_ERROR':
              console.log(data.error)
              console.log('Invitations Error')
              dispatch(setErrorMessage(data.error))
              break
            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'ROLES':
          switch (type) {
            case 'ROLES':
              let oldRoles = currentState.users.roles
              let newRoles = data.roles
              let updatedRoles = []
              // (mikekebert) Loop through the new roles and check them against the existing array
              newRoles.forEach((newRole) => {
                oldRoles.forEach((oldRole, index) => {
                  if (
                    oldRole !== null &&
                    newRole !== null &&
                    oldRole.role_id === newRole.role_id
                  ) {
                    // (mikekebert) If you find a match, delete the old copy from the old array
                    oldRoles.splice(index, 1)
                  }
                })
                updatedRoles.push(newRole)
              })
              // (mikekebert) When you reach the end of the list of new roles, simply add any remaining old roles to the new array
              if (oldRoles.length > 0)
                updatedRoles = [...updatedRoles, ...oldRoles]

              dispatch(setRoles(updatedRoles))

              removeLoadingProcess('ROLES')
              break

            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'USERS':
          switch (type) {
            case 'USERS':
              let oldUsers = currentState.users.users
              let newUsers = data.users

              let updatedUsers = []
              // (mikekebert) Loop through the new users and check them against the existing array
              newUsers.forEach((newUser) => {
                oldUsers.forEach((oldUser, index) => {
                  if (
                    oldUser !== null &&
                    newUser !== null &&
                    oldUser.user_id === newUser.user_id
                  ) {
                    // (mikekebert) If you find a match, delete the old copy from the old array
                    oldUsers.splice(index, 1)
                  }
                })
                updatedUsers.push(newUser)
              })
              // (mikekebert) When you reach the end of the list of new users, simply add any remaining old users to the new array
              if (oldUsers.length > 0)
                updatedUsers = [...updatedUsers, ...oldUsers]
              // (mikekebert) Sort the array by data created, newest on top
              updatedUsers.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setUsers(updatedUsers))

              removeLoadingProcess('USERS')
              break

            case 'USER':
              let user = data.user[0]
              dispatch(setUser(user))
              break

            case 'USER_UPDATED':
              const usersAfterUpdate = currentState.users.users.map((x) =>
                x.user_id === data.updatedUser.user_id ? data.updatedUser : x
              )
              dispatch(setUsers(usersAfterUpdate))
              dispatch(setUser(data.updatedUser))

              break

            case 'PASSWORD_UPDATED':
              // (eldersonar) Replace the user with the updated user based on password)
              setUsers((prevUsers) => {
                return prevUsers.map((x) =>
                  x.user_id === data.updatedUserPassword.user_id
                    ? data.updatedUserPassword
                    : x
                )
              })

              break

            case 'USER_CREATED':
              let usersCreated = [...currentState.users.users, data.user[0]]
              usersCreated.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )
              dispatch(setUsers(usersCreated))
              dispatch(setUser(data.user[0]))
              break

            case 'USER_DELETED':
              const index = currentState.users.users.findIndex(
                (v) => v.user_id === data
              )
              let alteredUsers = [...currentState.users.users]
              alteredUsers.splice(index, 1)
              dispatch(setUsers(alteredUsers))
              break

            case 'USER_ERROR':
              dispatch(setErrorMessage(data.error))
              break

            case 'USER_SUCCESS':
              dispatch(setSuccessMessage(data))
              break

            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'PRESENTATIONS':
          switch (type) {
            case 'CREDENTIAL_VERIFIED':
              setPendingConnectionID(data.connection_id)
              setVerifiedCredential(data.revealed_attrs)
              setVerificationStatus(true)

              break

            case 'VERIFICATION_FAILED':
              setVerifiedCredential('')
              setVerificationStatus(false)

              break

            case 'PRESENTATION_REPORTS':
              let oldPresentations =
                currentState.presentations.presentationReports
              let newPresentations = data.presentation_reports
              let updatedPresentations = []

              // (mikekebert) Loop through the new presentation and check them against the existing array
              newPresentations.forEach((newPresentation) => {
                oldPresentations.forEach((oldPresentation, index) => {
                  if (
                    oldPresentation !== null &&
                    newPresentation !== null &&
                    oldPresentation.presentation_exchange_id ===
                      newPresentation.presentation_exchange_id
                  ) {
                    // (mikekebert) If you find a match, delete the old copy from the old array
                    oldPresentations.splice(index, 1)
                  }
                })
                updatedPresentations.push(newPresentation)
                // (mikekebert) We also want to make sure to reset any pending connection IDs so the modal windows don't pop up automatically
                if (newPresentation.connection_id === pendingConnectionID) {
                  setPendingConnectionID('')
                }
              })
              // (mikekebert) When you reach the end of the list of new presentations, simply add any remaining old presentations to the new array
              if (oldPresentations.length > 0)
                updatedPresentations = [
                  ...updatedPresentations,
                  ...oldPresentations,
                ]
              // (mikekebert) Sort the array by date created, newest on top
              updatedPresentations.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setPresentationReports(updatedPresentations))
              removeLoadingProcess('PRESENTATIONS')

              break
            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'SERVER':
          switch (type) {
            case 'ANON_WEBSOCKET_READY':
              setReadyForAnonMessages(true)
              break

            case 'ADMIN_WEBSOCKET_READY':
              setReadyForAdminMessages(true)
              break

            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'SETTINGS':
          switch (type) {
            case 'SETTINGS_THEME':
              // Writing the recent theme to a local storage
              const stringMessageTheme = JSON.stringify(data.value)
              window.localStorage.setItem('recentTheme', stringMessageTheme)
              dispatch(setTheme(data.value))
              removeLoadingProcess('THEME')
              break

            case 'SETTINGS_SCHEMAS':
              dispatch(setSchemas(data))
              removeLoadingProcess('SCHEMAS')
              break

            case 'LOGO':
              dispatch(setLogo(handleImageSrc(data.image.data)))
              removeLoadingProcess('LOGO')
              break

            case 'SETTINGS_ORGANIZATION':
              dispatch(setOrganizationName(data.organizationName))
              dispatch(setSiteTitle(data.title))
              removeLoadingProcess('ORGANIZATION')
              break

            case 'SETTINGS_SMTP':
              dispatch(setSmtp(data.value))
              removeLoadingProcess('SMTP')
              break

            case 'SETTINGS_ERROR':
              dispatch(setErrorMessage(data.error))
              break

            case 'SETTINGS_SUCCESS':
              dispatch(setSuccessMessage(data))
              break

            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        default:
          setNotification(
            `Error - Unrecognized Websocket Message Type: ${context}`,
            'error'
          )
          break
      }
    } catch (error) {
      console.log('Error caught:', error)
      setNotification('Client Error - Websockets', 'error')
    }
  }

  function addLoadingProcess(process) {
    if (!session && !loginState.loggedIn) {
      anonLoadingList.push(process)
    } else {
      adminLoadingList.push(process)
    }
  }

  function clearLoadingProcess() {
    adminLoadingList.length = 0
    anonLoadingList.length = 0
    setAppIsLoaded(true)
  }

  function removeLoadingProcess(process) {
    if (!session && !loginState.loggedIn) {
      const index = anonLoadingList.indexOf(process)
      if (index > -1) {
        anonLoadingList.splice(index, 1)
      }

      if (anonLoadingList.length === 0) {
        setAppIsLoaded(true)
      }
    } else {
      const index = adminLoadingList.indexOf(process)
      if (index > -1) {
        adminLoadingList.splice(index, 1)
      }

      if (adminLoadingList.length === 0) {
        setAppIsLoaded(true)
      }
    }
  }

  function setUpUser(id, username, roles) {
    setSession(cookies.get('sessionId'))
    dispatch(setLoggedInUserId(id))
    dispatch(setLoggedInUsername(username))
    dispatch(setLoggedInRoles(roles))
  }

  // Update theme state locally
  const updateTheme = (update) => {
    updateState()
    return dispatch(setTheme({ ...theme, ...update }))
  }

  // Update theme in the database
  const saveTheme = () => {
    sendAdminMessage('SETTINGS', 'SET_THEME', theme)
  }

  const addStylesToArray = (key) => {
    let position = stylesArray.indexOf(key)
    // if cannot find indexOf style
    if (!~position) {
      setStylesArray((oldArray) => [...oldArray, `${key}`])
    }
  }

  const removeStylesFromArray = (undoKey) => {
    // Removing a style from an array of styles
    let index = stylesArray.indexOf(undoKey)
    if (index > -1) {
      stylesArray.splice(index, 1)
      setStylesArray(stylesArray)
    }
  }

  // Undo theme change
  const undoStyle = (undoKey) => {
    updateState()
    const recentTheme = JSON.parse(localStorage.getItem('recentTheme'))
    if (undoKey !== undefined) {
      for (let key in recentTheme)
        if ((key = undoKey)) {
          const undo = { [`${key}`]: recentTheme[key] }
          return dispatch(setTheme({ ...theme, ...undo }))
        }
    }
  }

  // Logout and redirect
  const handleLogout = (history) => {
    Axios({
      method: 'POST',
      url: '/api/user/log-out',
      withCredentals: true,
    }).then((res) => {
      setSession('')
      setAdminWebsocket(false)
      dispatch(logoutUser())
      dispatch(clearUsersState())
      dispatch(clearSettingsState())
      dispatch(clearPresentationsState())
      dispatch(clearContactsState())
      dispatch(clearInvitationsState())
      closeWSConnection(1000, 'Log out')
      if (history !== undefined) {
        history.push('/login')
      }
    })
  }

  if (
    (loginState.loggedIn && !appIsLoaded) ||
    (!loginState.loggedIn && !appIsLoaded)
  ) {
    // Show the spinner while the app is loading
    return (
      <ThemeProvider theme={theme}>
        <FullPageSpinner />
      </ThemeProvider>
    )
  } else if (!loginState.loggedIn && appIsLoaded) {
    return (
      <ThemeProvider theme={theme}>
        <NotificationProvider>
          <Router>
            <Switch>
              <Route
                path="/forgot-password"
                render={({ history }) => {
                  return (
                    <Frame id="app-frame">
                      <Main>
                        <ForgotPassword
                          history={history}
                          sendRequest={sendAdminMessage}
                        />
                      </Main>
                    </Frame>
                  )
                }}
              />
              <Route
                path="/password-reset"
                render={({ history }) => {
                  return (
                    <Frame id="app-frame">
                      <Main>
                        <PasswordReset
                          history={history}
                          sendRequest={sendAdminMessage}
                        />
                      </Main>
                    </Frame>
                  )
                }}
              />
              <Route
                path="/account-setup"
                render={({ history }) => {
                  return (
                    <Frame id="app-frame">
                      <Main>
                        <AccountSetup
                          history={history}
                          sendRequest={sendAdminMessage}
                          messageHandler={messageHandler}
                        />
                      </Main>
                    </Frame>
                  )
                }}
              />
              <Route
                path="/admin/login"
                render={({ history }) => {
                  return (
                    <Frame id="app-frame">
                      <Main>
                        <Login
                          history={history}
                          setUpUser={setUpUser}
                          sendRequest={sendAdminMessage}
                        />
                      </Main>
                    </Frame>
                  )
                }}
              />
              <Route
                path="/"
                exact
                render={() => {
                  return (
                    <Root
                      anonWebsocket={anonWebsocket}
                      readyForAnonMessages={readyForAnonMessages}
                      sendRequest={sendAnonMessage}
                      verificationStatus={verificationStatus}
                      verifiedCredential={verifiedCredential}
                    />
                  )
                }}
              />
              <Route exact path="/admin">
                <Redirect to="/admin/login" />
              </Route>
              <Route path="/:any">
                <Redirect to="/" />
              </Route>
            </Switch>
          </Router>
        </NotificationProvider>
      </ThemeProvider>
    )
  } else {
    // loggedIn and appIsLoaded
    return (
      <ThemeProvider theme={theme}>
        <NotificationProvider>
          <SessionProvider logout={handleLogout} sessionTimer={sessionTimer}>
            <Router>
              <Switch>
                <Route
                  path="/admin"
                  render={() => {
                    return (
                      <AdminRoute
                        handleLogout={handleLogout}
                        sendMessage={sendAdminMessage}
                        updateTheme={updateTheme}
                        saveTheme={saveTheme}
                        undoStyle={undoStyle}
                        stylesArray={stylesArray}
                        addStylesToArray={addStylesToArray}
                        removeStylesFromArray={removeStylesFromArray}
                        setWaitingForContacts={setWaitingForContacts}
                        setWaitingForPendingConnections={
                          setWaitingForPendingConnections
                        }
                        waitingForContacts={waitingForContacts}
                        waitingForPendingConnections={
                          waitingForPendingConnections
                        }
                      />
                    )
                  }}
                />
                {/* Redirect to root if no route match is found */}
                <Route render={() => <Redirect to="/admin" />} />
              </Switch>
            </Router>
          </SessionProvider>
        </NotificationProvider>
      </ThemeProvider>
    )
  }
}

export default App
